"use client";

import { twMerge } from "tailwind-merge";
import { getLeadIcon } from "@/app/(main)/(club)/mes-clubs/[id]/leads/utils";
import { ButtonProps } from "@/TS/Interfaces/global_interfaces";
const baseClasses = "button";
const sharedFilledClasses = "text-white hover:bg-opacity-80";
const sharedUnfilledClasses = "bg-transparent hover:text-white";
const themeColors = {
  primary: "primary",
  secondary: "secondary",
  accept: "accept",
  refuse: "primary",
  not_transform: "primary",
  modify: "accept",
  cancel: "secondary"
};
function getButtonClasses(variant: string, colorTheme: string, style?: React.CSSProperties) {
  const color = themeColors[colorTheme as keyof typeof themeColors] || themeColors.primary;
  if (variant === "filled") {
    return twMerge(`bg-${color}`, style?.border ? "border-0" : "border-transparent", sharedFilledClasses);
  }
  return twMerge(style?.border ? "border-0" : `border-${color}`, `text-${color}`, sharedUnfilledClasses, `hover:bg-${color}`);
}
export default function Button({
  variant = "filled",
  type = "button",
  disabled,
  colorTheme = "primary",
  onClick,
  children,
  className,
  style,
  ...rest
}: ButtonProps) {
  const selectedClasses = getButtonClasses(variant, colorTheme, style);
  return <button type={type} disabled={disabled} className={twMerge(baseClasses, selectedClasses, className)} onClick={onClick} {...rest} data-sentry-component="Button" data-sentry-source-file="button.tsx">
      {getLeadIcon(colorTheme)}
      {children}
    </button>;
}